import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vxe_column = _resolveComponent("vxe-column")!
  const _component_vxe_option = _resolveComponent("vxe-option")!
  const _component_vxe_select = _resolveComponent("vxe-select")!
  const _component_vxe_input = _resolveComponent("vxe-input")!
  const _component_vxe_table = _resolveComponent("vxe-table")!

  return (_openBlock(), _createBlock(_component_vxe_table, {
    ref: "attributeGrid",
    "max-height": "400",
    data: _ctx.groupValues,
    "edit-config": {
      trigger: 'click',
      mode: 'cell',
      icon: 'fa fa-pencil',
      showStatus: true
    },
    "row-style": _ctx.rowStyle,
    "auto-resize": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_vxe_column, {
        field: "Attribute.Name",
        title: "名稱",
        width: "100",
        resizable: ""
      }),
      _createVNode(_component_vxe_column, {
        field: "Attribute.Code",
        title: "代碼",
        width: "100",
        resizable: ""
      }),
      _createVNode(_component_vxe_column, {
        field: "AttributeValue",
        title: "數值",
        resizable: ""
      }, {
        default: _withCtx(({row}) => [
          (row.Attribute.Selectable)
            ? (_openBlock(), _createBlock(_component_vxe_select, {
                key: 0,
                modelValue: row.SelectedValues,
                "onUpdate:modelValue": ($event: any) => ((row.SelectedValues) = $event),
                transfer: "",
                clearable: "",
                multiple: row.Attribute.Multiple,
                onChange: ($event: any) => (_ctx.onChange(row.Attribute.Id))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.Attribute.Values, (item) => {
                    return (_openBlock(), _createBlock(_component_vxe_option, {
                      key: `value-${item.Id}`,
                      label: item.TextValue,
                      value: item.Id
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "multiple", "onChange"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_vxe_input, {
                  class: "w-1/3 mr-1",
                  placeholder: "請輸入顯示用文字",
                  modelValue: row.AttributeValues[0].TextValue,
                  "onUpdate:modelValue": ($event: any) => ((row.AttributeValues[0].TextValue) = $event),
                  transfer: "",
                  clearable: "",
                  onChange: ($event: any) => (_ctx.onChange(row.Attribute.Id))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]),
                _createVNode(_component_vxe_input, {
                  class: "w-1/3 mr-1",
                  placeholder: "請輸入比較用數值",
                  type: "number",
                  modelValue: row.AttributeValues[0].DecimalValue,
                  "onUpdate:modelValue": ($event: any) => ((row.AttributeValues[0].DecimalValue) = $event),
                  transfer: "",
                  clearable: "",
                  onChange: ($event: any) => (_ctx.onChange(row.Attribute.Id))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]),
                _createVNode(_component_vxe_input, {
                  class: "w-1/3",
                  placeholder: "請輸入實際值",
                  modelValue: row.AttributeValues[0].Value,
                  "onUpdate:modelValue": ($event: any) => ((row.AttributeValues[0].Value) = $event),
                  transfer: "",
                  clearable: "",
                  onChange: ($event: any) => (_ctx.onChange(row.Attribute.Id))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data", "row-style"]))
}