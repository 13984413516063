
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  reactive,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import "@/global-components/ckeditor/styles.css";
import ProductAttributeGrid from '@/cloudfun/components/ProductAttributeGrid.vue'
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import SliderEditor from '@/cloudfun/components/SliderEditor.vue'
import { VueUploadItem } from "vue-upload-component";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    FileUploader,
    ProductAttributeGrid,
    SliderEditor,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const panelVisible = reactive({
      attribute: true,
      album: true,
      introduction: true,
      specification: true,
      content: true
    })

    const gridOptions: GridOptions = {
      stripe: false,
      title: "產品",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      exportConfig: {
        filename: "產品清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        // columns: printColumns
      },
      columns: [
        {
          field: "Name",
          title: "產品名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Category.Name",
          title: "分類",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => (cellValue ? "是" : "否")
        },
        {
          field: "StartDate",
          title: "上架日",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => CloudFun.utils.formatDate(cellValue, "yyyy/MM/dd")
        },
        {
          field: "EndDate",
          title: "下架日",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => CloudFun.utils.formatDate(cellValue, "yyyy/MM/dd")
        }
      ],
      promises: {
        query: model
          ? params => {
            return model.dispatch("product/query", params);
          }
          : undefined,
        queryAll: model ? () => model.dispatch("product/query") : undefined,
        save: model
          ? params => {
            if (params.updateRows) {
              for (const row of params.updateRows) {
                for (const attr of row.ProductAttributeValues) {
                  delete attr.AttributeValue.$id;
                }
              }
            }
            return model.dispatch("product/save", params)
          }
          : undefined
      },
      modalConfig: { width: "80%", height: "80%", showFooter: true },
      //   treeConfig: { children: "Children" }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 150,
      titleAlign: "right",
      // size: "medium",
      items: [
        {
          field: "Photo.Uri",
          span: 24,
          slots: { default: "columns-photo" }
        },
        // {
        //   field: "Name",
        //   title: "名稱",
        //   span: 24,
        //   itemRender: {
        //     name: "$input",
        //     props: { placeholder: "請輸入文字", clearable: true },
        //     attrs: { type: "text" }
        //   }
        // },
        // {
        //   field: "Number",
        //   title: "產品型號",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: { placeholder: "請輸入文字", clearable: true },
        //     attrs: { type: "text" }
        //   }
        // },
        // {
        //   field: "CategoryId",
        //   title: "類別",
        //   span: 12,

        //   slots: { default: "column-product-category-id" }
        // },
        {
          field: "StartDate",
          title: "上架日期",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "date", placeholder: "上架日期" }
          }
        },
        {
          field: "EndDate",
          title: "下架日期",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "date", placeholder: "下架日期" }
          }
        },
        {
          field: "Price",
          title: "定價",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "定價" }
          }
        },
        {
          field: "SalePrice",
          title: "優惠價格",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "優惠價格" }
          }
        },
        {
          field: "DifferentStationFee",
          title: "甲租乙還費用",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "費用" }
          }
        },
        // {
        //   field: "Published",
        //   title: "已發佈",
        //   span: 12,

        //   itemRender: {
        //     name: "$select",
        //     options: [
        //       { value: false, label: "否" },
        //       { value: true, label: "是" }
        //     ]
        //   }
        // },
        // {
        //   field: "Ordinal",
        //   title: "排序",
        //   span: 12,
        //   titleWidth: 60,
        //   itemRender: {
        //     name: "$input",
        //     props: { type: "number", placeholder: "請輸入數字" }
        //   }
        // },
      ],
      rules: {
        SalePrice: [{ required: true }]
      }
    };

    const categoryIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇分類",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("category/find", value), // eslint-disable-line
        query: params => {
          if (params.condition) params.condition.and("Type", Operator.Equal, 1);
          return model!.dispatch("category/query", params); // eslint-disable-line
        }
      }
    };

    const tagsSelectOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇標籤",
      textField: "Name",
      valueField: "Id",
      columns: [{ field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true }],
      multiselect: true,
      showHeader: true,
      addIfNotExists: true,
      promises: {
        find: (value) => model!.dispatch("tag/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("tag/query", params), // eslint-disable-line
        insert: (row) => model!.dispatch("tag/insert", row), // eslint-disable-line
      }
    }

    const pictureFormOptions = {
      titleWidth: 40,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 14,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } }
        }, {
          field: 'Ordinal',
          title: '順序',
          span: 10,
          titleWidth: 40,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        }, {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } }
        },
      ],
      rules: {
        Name: [{ type: 'string', max: 128 }]
      }
    }

    const editor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    }

    return {
      grid,
      panelVisible,
      gridOptions,
      formOptions,
      categoryIdSelectOptions,
      tagsSelectOptions,
      pictureFormOptions,
      editor,
      editorConfig,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    onGridAdd(row: any, callback: any) {
      row.Published = false;
      row.Ordinal = 0;
      row.Type = 0;
      row.DifferentStationFee = 0;
      row.Introduction = { Content: "" };
      row.Specification = { Content: "" };
      row.Content = { Content: "" };
      callback();
    },
    async onGridEdit(row: any, callback: any) {
      if (row.Id) {
        const entity = await this.$model.dispatch("product/find", row.Id)

        if (entity) Object.assign(row, entity);
        let tempAttributeValues: any[] = [];
        for (const attr of row.ProductAttributeValues) {
          if (attr.AttributeValue.Attribute) tempAttributeValues = tempAttributeValues.concat(attr.AttributeValue.Attribute.Values);
          if (!attr.AttributeValue.Id) attr.AttributeValue = tempAttributeValues.find(e => e.Id === attr.AttributeValueId)
        }
      }
      callback();
    },
    onSliderEdit(image: any, callback: any) {
      image.AlbumId = this.grid.editingRow.PictureAlbumId;
      callback();
    },
    async onSliderRemove(image: any, callback: any) {
      await this.$model.dispatch('picture/delete', image.Id);
      callback();
    },
    async onSliderSave(params: any, callback: any) {
      await this.$model.dispatch('picture/save', params).then(
        () => {
          this.$model.dispatch('pictureAlbum/find', this.grid.editingRow.PictureAlbumId).then(
            (entity) => {
              entity.Pictures.forEach((picture: any) => {
                delete picture.$id;
                delete picture.Album;
              });
              const sliderEditor: any = this.$refs.sliderEditor;
              sliderEditor.reload(entity.Pictures.sort((a: any, b: any) => a.Ordinal - b.Ordinal))
            },
            reason => { CloudFun.send('error', { subject: '重載失敗', content: reason }) }
          )
        },
        reason => { CloudFun.send('error', { subject: '保存失敗', content: reason }) }
      );
      callback();
    },
  }
});
